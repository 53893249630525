import * as Blockly from "blockly/core";
import "blockly/javascript";

// const specialProperties = [
//   "text",
//   "type",
//   "name",
//   "placeholder",
//   "src",
//   "alt",
//   "label",
//   "selected",
// ];
const textboxAttributes = ["type", "placeholder", "name"];
const datetimeAttributes = ["type"];
const radioButtonAttributes = ["checked", "textContent"];

Blockly.JavaScript["when_do_screen"] = function (block) {
  var screen = block.getFieldValue("current_screen_input");
  var statements_do = Blockly.JavaScript.statementToCode(block, "do");
  var code = `if (screenDiv.id === '_${screen}') {
        ${statements_do}
    };`;
  // console.log("when_do_screen code", code);
  return code;
};

Blockly.JavaScript["al_screen_getter"] = function (block) {
  var dropdown_set_screen_options = block.getFieldValue("current_screen_input");
  var dropdown_screen_properties = block.getFieldValue("screen_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = `window.getComputedStyle(document.getElementById('_${dropdown_set_screen_options}')).${dropdown_screen_properties}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_screen_setter"] = function (block) {
  var dropdown_set_screen_options = block.getFieldValue("current_screen_input");
  var dropdown_screen_properties = block.getFieldValue("screen_properties");
  var value_set_screen =
    Blockly.JavaScript.valueToCode(block, "set screen", null) || '""';
  // if (!value_set_screen.match('state')) {
  //     value_set_screen = "\"" + value_set_screen + "\""
  // }
  // TODO: Assemble JavaScript into code variable.
  var code = `document.getElementById('_${dropdown_set_screen_options}').style.${dropdown_screen_properties} = ${value_set_screen};`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.JavaScript["al_move_screen"] = function (block) {
  var dropdown_screen_options = block.getFieldValue("screen_input");
  // TODO: Assemble JavaScript into code variable.
  // var code = 'this.props.navigation.navigate("' + dropdown_screen_options + '");';
  var code = `onNavigate('/${dropdown_screen_options}');`;
  return code;
};

Blockly.JavaScript["when_do_buttons"] = function (block) {
  var button_input = block.getFieldValue("button_input");
  // var dropdown_action_block = block.getFieldValue('action_options');
  var statements_do = Blockly.JavaScript.statementToCode(block, "do");
  // TODO: Assemble JavaScript into code variable.
  var code = `
if (button${button_input}) {
  button${button_input}.addEventListener('click', async function () {
    ${statements_do}
  });
}
`;
  // console.log("when_do_buttons code", code);
  return code;
};

Blockly.JavaScript["al_button_getter"] = function (block) {
  var button_input = block.getFieldValue("button_input");
  var button_properties = block.getFieldValue("button_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (button_properties === "textContent") {
    //     code = 'this.state["' + dropdown_component_input + '"].' + button_properties;
    code = `document.getElementById('_${button_input}').textContent`;
  } else {
    //     code = 'this.state["' + dropdown_component_input + '"].style.' + button_properties;
    code = `window.getComputedStyle(document.getElementById('_${button_input}')).${button_properties}`;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_button_setter"] = function (block) {
  var button_input = block.getFieldValue("button_input");
  var button_properties = block.getFieldValue("button_properties");
  var value_set_button =
    Blockly.JavaScript.valueToCode(block, "set button", null) || '""';
  // if (!value_set_button.match('state')) {
  //     value_set_button = "\"" + value_set_button + "\""
  // }
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (button_properties === "textContent") {
    code = `document.getElementById('_${button_input}').textContent = ${value_set_button};`;
  } else {
    code = `document.getElementById('_${button_input}').style.${button_properties} = ${value_set_button};`;
  }
  return code;
};

Blockly.JavaScript["al_label_getter"] = function (block) {
  var label_id = block.getFieldValue("label_input");
  var label_prop = block.getFieldValue("label_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (label_prop === "textContent") {
    code = `document.getElementById('_${label_id}').textContent`;
  } else {
    code = `window.getComputedStyle(document.getElementById('_${label_id}')).${label_prop}`;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_label_setter"] = function (block) {
  var label_id = block.getFieldValue("label_input");
  var label_prop = block.getFieldValue("label_properties");
  var set_string =
    Blockly.JavaScript.valueToCode(block, "set label", null) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (label_prop === "textContent") {
    code = `document.getElementById('_${label_id}').textContent = ${set_string};`;
  } else {
    code = `document.getElementById('_${label_id}').style.${label_prop} = ${set_string};`;
  }
  return code;
};

Blockly.JavaScript["al_text_input_change"] = function (block) {
  var textbox_id = block.getFieldValue("textInput_input");
  var event_type = block.getFieldValue("event_type");
  var action = Blockly.JavaScript.statementToCode(block, "action");
  var code = `
if (textbox${textbox_id}) {
    textbox${textbox_id}.addEventListener('${event_type}', function () {
        ${action}
    });
}
`;
  // console.log("al_text_input_change code", code);
  return code;
};

Blockly.JavaScript["al_text_input_getter"] = function (block) {
  var textbox_id = block.getFieldValue("textInput_input");
  var textbox_prop = block.getFieldValue("text_input_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (textbox_prop === "value") {
    code = `document.getElementById('_${textbox_id}').value`;
  } else if (textboxAttributes.includes(textbox_prop)) {
    code = `document.getElementById('_${textbox_id}').getAttribute('${textbox_prop}')`;
  } else {
    code = `window.getComputedStyle(document.getElementById('_${textbox_id}')).${textbox_prop}`;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_text_input_setter"] = function (block) {
  var textbox_id = block.getFieldValue("textInput_input");
  var textbox_prop = block.getFieldValue("text_input_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set text input", null) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (textbox_prop === "value") {
    code = `document.getElementById('_${textbox_id}').value = ${set_value};`;
  } else if (textboxAttributes.includes(textbox_prop)) {
    code = `document.getElementById('_${textbox_id}').setAttribute('${textbox_prop}', ${set_value});`;
  } else {
    code = `document.getElementById('_${textbox_id}').style.${textbox_prop} = ${set_value};`;
  }
  return code;
};

Blockly.JavaScript["al_image_getter"] = function (block) {
  var image_id = block.getFieldValue("image_input");
  var image_prop = block.getFieldValue("image_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = `window.getComputedStyle(document.getElementById('_${image_id}')).${image_prop}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_image_setter"] = function (block) {
  var image_id = block.getFieldValue("image_input");
  var image_prop = block.getFieldValue("image_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set image", null) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = `document.getElementById('_${image_id}').style.${image_prop} = ${set_value};`;
  return code;
};

Blockly.JavaScript["al_radio_button_getter"] = function (block) {
  var radio_id = block.getFieldValue("radio_button_input");
  var radio_prop = block.getFieldValue("radio_button_properties");
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (radioButtonAttributes.includes(radio_prop)) {
    if (radio_prop === "textContent") {
      code = `document.getElementById('_${radio_id}').${radio_prop}`;
    } else if (radio_prop === "checked") {
      code = `document.getElementById('_${radio_id}').querySelector("input").${radio_prop}`;
    }
  } else {
    code = `window.getComputedStyle(document.getElementById('_${radio_id}')).${radio_prop}`;
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_radio_button_setter"] = function (block) {
  var radio_id = block.getFieldValue("radio_button_input");
  var radio_prop = block.getFieldValue("radio_button_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set radio button", null) || '""';
  // TODO: Assemble JavaScript into code variable.
  var code = ``;
  if (radioButtonAttributes.includes(radio_prop)) {
    if (radio_prop === "textContent") {
      code = `document.getElementById('_${radio_id}').childNodes[0].${radio_prop} = ${set_value};
document.getElementById("_${radio_id}").querySelector("input").value = ${set_value};`;
    } else if (radio_prop === "checked") {
      code = `document.getElementById('_${radio_id}').querySelector("input").${radio_prop} = ${set_value};`;
    }
  } else {
    code = `document.getElementById('_${radio_id}').style.${radio_prop} = ${set_value};`;
  }
  return code;
};

Blockly.JavaScript["al_radio_button_get_selected"] = function (block) {
  var group_name = block.getFieldValue("radio_button_input");
  var code = `(group?.${group_name} || "")`;
  // console.log("al_radio_button_change code", code);
  return [code, null];
};

Blockly.JavaScript["al_radio_button_group_set"] = function (block) {
  var group_name = block.getFieldValue("radio_button_input");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set group value", null) || '""';
  let code = `
group["${group_name}"] = ${set_value};
let input = document.querySelectorAll('input[name="${group_name}"]');
input.forEach((i) => {
    if (i.id == ${set_value}) i.checked = true;
    else i.checked = false;
});
`;
  return code;
};

Blockly.JavaScript["al_icon_event"] = function (block) {
  var icon_id = block.getFieldValue("icon_input");
  var event = block.getFieldValue("event_type");
  var action = Blockly.JavaScript.statementToCode(block, "action");
  let code = `if (icon${icon_id}) {
  icon${icon_id}.addEventListener('${event}', function () {
    ${action}
  });
}`;
  return code;
};

// Blockly.JavaScript["al_icon_getter"] = function (block) {
//   var icon_id = block.getFieldValue("icon_input");
//   var prop = block.getFieldValue("icon_properties");
//   var code = "";
//   if (prop === "title") {
//     code = `document.getElementById('_${icon_id}').getAttribute('title')`;
//   } else {
//     code = `document.getElementById('_${icon_id}').style.${prop}`;
//   }
//   return [code, null];
// };

Blockly.JavaScript["al_datetime_input_getter"] = function (block) {
  var dt_id = block.getFieldValue("datetime_input");
  var prop = block.getFieldValue("datetime_properties");
  var code = "";
  if (prop === "value") {
    code = `document.getElementById('_${dt_id}').value`;
  } else if (datetimeAttributes.includes(prop)) {
    code = `document.getElementById('_${dt_id}').getAttribute('${prop}')`;
  } else {
    code = `document.getElementById('_${dt_id}').style.${prop}`;
  }
  return [code, null];
};

Blockly.JavaScript["al_datetime_input_setter"] = function (block) {
  var dt_id = block.getFieldValue("datetime_input");
  var prop = block.getFieldValue("datetime_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set value", null) || '""';
  var code = "";
  if (prop === "value") {
    code = `document.getElementById('_${dt_id}').value = ${set_value};`;
  } else if (datetimeAttributes.includes(prop)) {
    code = `document.getElementById('_${dt_id}').setAttribute('${prop}', ${set_value});`;
  } else {
    code = `document.getElementById('_${dt_id}').style.${prop} = ${set_value};`;
  }
  return code;
};

Blockly.JavaScript["al_datetime_event"] = function (block) {
  var dt_id = block.getFieldValue("datetime_input");
  var event = block.getFieldValue("event_type");
  var action = Blockly.JavaScript.statementToCode(block, "action");
  var code = `if (dateTimeInput${dt_id}) {
  dateTimeInput${dt_id}.addEventListener('${event}', function () {
    ${action}
  });
}`;
  // console.log("al_datetime_event code", code);
  return code;
};

Blockly.JavaScript["al_switch_input_getter"] = function (block) {
  var switch_id = block.getFieldValue("switch_input");
  //   var prop = block.getFieldValue("switch_properties");
  var code = `document.getElementById("_${switch_id}").querySelector("input").checked`;
  return [code, null];
};

Blockly.JavaScript["al_switch_input_setter"] = function (block) {
  var switch_id = block.getFieldValue("switch_input");
  //   var prop = block.getFieldValue("switch_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, "set value", null) || '""';
  var code = `if (document.getElementById("_${switch_id}").querySelector("input").checked !== ${set_value}) {
  document.getElementById("_${switch_id}").querySelector("input").click();
};`;
  return code;
};

Blockly.JavaScript["al_switch_event"] = function (block) {
  var switch_id = block.getFieldValue("switch_input");
  var event = block.getFieldValue("event_type");
  var action = Blockly.JavaScript.statementToCode(block, "action");
  var code = `if (switch${switch_id}) {
    let input = switch${switch_id}.querySelector("input");
    input.addEventListener("${event}", function () {
        ${action}
    });
  }`;
  // console.log("al_switch_event code", code);
  return code;
};

Blockly.JavaScript["set_color"] = function (block) {
  var get_color = block.getFieldValue("COLOR");
  // TODO: Assemble JavaScript into code variable.
  var code = `"${get_color}"`;
  return [code, null];
};

Blockly.JavaScript["set_text_input"] = function (block) {
  var text_name = block.getFieldValue("NAME");
  // TODO: Assemble JavaScript into code variable.
  var code = `"${text_name}"`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_numerical_input"] = function (block) {
  var number_number_input = block.getFieldValue("number_input");
  // TODO: Assemble JavaScript into code variable.
  // var code = number_number_input
  // TODO: Change ORDER_NONE to the correct strength.
  return [number_number_input, null];
};

Blockly.JavaScript["al_boolean"] = function (block) {
  var dropdown_boolean = block.getFieldValue("boolean");
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_boolean}"`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_keyboard_type_input"] = function (block) {
  var dropdown_keyboard_type_options = block.getFieldValue(
    "keyboard_type_options"
  );
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_keyboard_type_options}"`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_screen_justification"] = function (block) {
  var dropdown_justification_options = block.getFieldValue(
    "justification_options"
  );
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_justification_options}"`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_screen_alignment"] = function (block) {
  var dropdown_alignment_options = block.getFieldValue("alignment_options");
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_alignment_options}"`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null];
};

Blockly.JavaScript["al_image_size"] = function (block) {
  var resize = block.getFieldValue("image_resize");
  var code = `"${resize}"`;
  return [code, null];
};

Blockly.JavaScript["al_insert_table_row"] = function (block) {
  // var table_id = block.getFieldValue('table_input_field');
  // var column_id = block.getFieldValue('column_input_field');
  // var row_index = Blockly.JavaScript.valueToCode(block, 'row_number', null) || "\"\"";
  // var get_value_from = Blockly.JavaScript.valueToCode(block, 'get_value_from', null) || "\"\"";
  return null;
};

Blockly.JavaScript["al_insert_table_row_end"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
    (t) => t.id === table_id
  );
  let columnData = table["columnOrder"].map((cId) => {
    return [
      cId,
      Blockly.JavaScript.valueToCode(block, "column_input" + cId, null) || "''",
    ];
  });
  let code = `await addRowEnd('` + table_id + `', {`;
  columnData.forEach((cData) => {
    code += `"${cData[0]}": ${cData[1]},`;
  });
  code += `});`;
  return code;
};

Blockly.JavaScript["al_update_table_data"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var column_id = block.getFieldValue("column_input_field");
  var conditional_value =
    Blockly.JavaScript.valueToCode(block, "get_value", null) || "''";
  var column1_id = block.getFieldValue("column_input1_field");
  var setter_value =
    Blockly.JavaScript.valueToCode(block, "set_value", null) || "''";
  var code = `await updateOneRow('${table_id}', '${column_id}', ${conditional_value}, '${column1_id}', ${setter_value});`;
  return code;
};

Blockly.JavaScript["al_update_table_all_data"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var column_id = block.getFieldValue("column_input_field");
  var conditional_value =
    Blockly.JavaScript.valueToCode(block, "get_value", null) || "''";
  var column1_id = block.getFieldValue("column_input1_field");
  var setter_value =
    Blockly.JavaScript.valueToCode(block, "set_value", null) || "''";
  var code = `await updateAllRows('${table_id}', '${column_id}', ${conditional_value}, '${column1_id}', ${setter_value});`;
  return code;
};

Blockly.JavaScript["al_delete_one_row"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var column_id = block.getFieldValue("column_input_field");
  var condition_value =
    Blockly.JavaScript.valueToCode(block, "condition_value", null) || "''";
  var code = `await deleteFirstRow('${table_id}', '${column_id}', ${condition_value});`;
  return code;
};

Blockly.JavaScript["al_delete_one_row_conditional"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var condition_value = Blockly.JavaScript.valueToCode(
    block,
    "condition_value",
    null
  );
  var code = `await deleteFirstRowCondition('${table_id}', \`${condition_value}\`);`;
  return code;
};

Blockly.JavaScript["al_delete_multiple_row"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var column_id = block.getFieldValue("column_input_field");
  var condition_value =
    Blockly.JavaScript.valueToCode(block, "condition_value", null) || "''";
  var code = `await deleteRows('${table_id}', '${column_id}', ${condition_value});`;
  return code;
};

Blockly.JavaScript["al_delete_multiple_row_conditional"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var condition = Blockly.JavaScript.valueToCode(
    block,
    "condition_value",
    null
  );
  var code = `await deleteRowsCondition('${table_id}', \`${condition}\`);`;
  return code;
};

Blockly.JavaScript["al_get_table_row"] = function (block) {
  var tableId = block.getFieldValue("table_input_field");
  var columnId = block.getFieldValue("column_input_field");
  var index = Blockly.JavaScript.valueToCode(block, "row_number", null) || "''";
  var code = `await getColumnVal('${tableId}', '${columnId}', ${index})`;
  return [code, null];
};

Blockly.JavaScript["al_get_table_row_conditional"] = function (block) {
  var tableId = block.getFieldValue("table_input_field");
  var columnId = block.getFieldValue("column_input_field");
  var value =
    Blockly.JavaScript.valueToCode(block, "where_value", null) || "''";
  var code = `await getFirstRowValue('${tableId}', '${columnId}', ${value});`;
  return [code, null];
};

Blockly.JavaScript["al_get_table_row_multiple_conditional"] = function (block) {
  var tableId = block.getFieldValue("table_input_field");
  var condition =
    Blockly.JavaScript.valueToCode(block, "where_value", null) || "";
  var code = `await getFirstRow('${tableId}', \`${condition}\`);`;
  return [code, null];
};

Blockly.JavaScript["al_get_table_row_multiple_conditional_male"] = function (
  block
) {
  var tableId = block.getFieldValue("table_input_field");
  var condition =
    Blockly.JavaScript.valueToCode(block, "where_value", null) || "";
  var code = `await getFirstRow('${tableId}', \`${condition}\`);`;
  return code;
};

Blockly.JavaScript["al_get_table_rows"] = function (block) {
  var table_id = block.getFieldValue("table_input_field");
  var code = `await getRows('${table_id}')`;
  return [code, null];
};

Blockly.JavaScript["al_get_table_rows_conditional"] = function (block) {
  var tableId = block.getFieldValue("table_input_field");
  var columnId = block.getFieldValue("column_input_field");
  var value =
    Blockly.JavaScript.valueToCode(block, "where_value", null) || "''";
  var code = `await getRowsValue('${tableId}', '${columnId}', ${value});`;
  return [code, null];
};

Blockly.JavaScript["al_get_table_rows_multiple_conditional"] = function (
  block
) {
  var tableId = block.getFieldValue("table_input_field");
  var condition =
    Blockly.JavaScript.valueToCode(block, "where_value", null) || "";
  var code = `await getRowsConditional('${tableId}', \`${condition}\`);`;
  return [code, null];
};

Blockly.JavaScript["al_where_row_column"] = function (block) {
  return null;
};

Blockly.JavaScript["al_column_condition"] = function (block) {
  var column_id = block.getFieldValue("column_input_field");
  var condition = block.getFieldValue("comparison_operator");
  var value =
    Blockly.JavaScript.valueToCode(block, "condition_value", null) || "''";
  var code = `data['${column_id}'] ${condition} ${value}`;
  return [code, null];
};

Blockly.JavaScript["al_next_row"] = function (block) {
  return null;
};
