import app from 'firebase/app'
import 'firebase/database'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig)
        this.db = app.database()
    }
    // gettables = (user) => this.db.ref('/')
    createOneTable = (user, table) => {
        let now = Date.now()
        let newTable = {}
        let columnOrder = []
        let userBranch = this.db.ref('/' + user).push()
        newTable[userBranch.key] = {
            name: table.name,
            id: userBranch.key,
            createdOn: now,
            lastUpdatedOn: now,
        }
        table.column.map(c => {
            let columnId = this.db.ref('/' + user + '/' + userBranch.key + '/columnData').push()
            columnOrder.push(columnId.key)
            newTable[userBranch.key] = {
                ...newTable[userBranch.key],
                columnData: {
                    ...newTable[userBranch.key].columnData,
                    [columnId.key]: {
                        name: c.columnName
                    }
                }
            }
            return null
        })
        newTable[userBranch.key] = {
            ...newTable[userBranch.key],
            columnOrder: columnOrder,
        }
        userBranch.set(newTable[userBranch.key])
    }
    updateTableName = (user, tableId, newName) => this.db.ref('/' + user + '/' + tableId).update({ name: newName, lastUpdatedOn: Date.now() })
    deleteTable = (user, tableId) => this.db.ref('/' + user + '/' + tableId).remove()
    addColumn = async (user, tableId, index, colName) => {
        let addColumn = {}
        addColumn['lastUpdatedOn'] = Date.now()
        let newColumn = this.db.ref('/' + user + '/' + tableId + '/columnData').push()
        newColumn.set({ name: colName })
        let column = this.db.ref('/' + user + '/' + tableId + '/columnOrder')
        column.once('value', snap => {
            addColumn['columnOrder'] = snap.val()
        })
        addColumn['columnOrder'].splice(index, 0, newColumn.key)
        this.db.ref('/' + user + '/' + tableId).update(addColumn)
        return newColumn.key
    }
    updateColumnName = (user, tableId, colId, newName) => {
        let updateColumnName = {}
        updateColumnName['lastUpdatedOn'] = Date.now()
        updateColumnName['columnData/' + colId + '/name'] = newName
        this.db.ref('/' + user + '/' + tableId).update(updateColumnName)
    }
    deleteColumn = (user, tableId, colId, colName) => {
        let deleteColumn = {}
        let column = this.db.ref('/' + user + '/' + tableId + '/columnOrder')
        column.once('value', snap => {
            deleteColumn['columnOrder'] = snap.val().filter(c => c !== colId)
        })
        let row = this.db.ref('/' + user + '/' + tableId + '/rowData')
        row.once('value', snap => {
            deleteColumn['rowData'] = snap.val() || {}
        })
        deleteColumn['columnData/' + colId] = null
        Object.keys(deleteColumn['rowData']).map(rowId => {
            if (colId in deleteColumn['rowData'][rowId]) delete deleteColumn['rowData'][rowId][colId]
            return null
        })
        deleteColumn['lastUpdatedOn'] = Date.now()
        this.db.ref('/' + user + '/' + tableId).update(deleteColumn)
    }
    addRow = async (user, table, index, data) => {
        let rowOrderArray = []
        let newRowData = this.db.ref('/' + user + '/' + table + '/rowData').push()
        newRowData.set(data)
        let rowOrder = this.db.ref('/' + user + '/' + table + '/rowOrder')
        rowOrder.once('value', snap => rowOrderArray = snap.val() || [])
        if (rowOrderArray.length === 0) {
            rowOrder.set([newRowData.key])
        } else {
            rowOrderArray.splice(index, 0, newRowData.key)
            rowOrder.set(rowOrderArray)
        }
    }
    updateRow = (user, table, index, data) => {
        let rowId = null
        let rowOrderArray = []
        let rowOrder = this.db.ref('/' + user + '/' + table + '/rowOrder')
        rowOrder.once('value', snap => rowOrderArray = snap.val() || [])
        rowId = rowOrderArray[index]
        this.db.ref('/' + user + '/' + table + '/rowData/' + rowId).update(data)
    }
    deleteRow = (user, table, index) => {
        let rowId = null
        let rowOrderArray = []
        let rowOrder = this.db.ref('/' + user + '/' + table + '/rowOrder')
        rowOrder.once('value', snap => rowOrderArray = snap.val() || [])
        rowId = rowOrderArray[index]
        rowOrderArray.splice(index, 1)
        rowOrder.set(rowOrderArray)
        this.db.ref('/' + user + '/' + table + '/rowData/' + rowId).remove()
    }
    getRows = async (user, tableId) => {
        let table = await (await this.db.ref('/' + user + '/' + tableId).once('value')).val()
        let rows = []
        table['rowOrder'].forEach(rowId => {
            let row = {}
            Object.keys(table['rowData'][rowId]).forEach(colId => {
                row[table['columnData'][colId]['name']] = table['rowData'][rowId][colId]
            })
            rows.push(row)
        })
        // console.log(rows)
        return rows
    }
}

export default Firebase