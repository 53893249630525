import * as Blockly from "blockly/core";
import {
  k_textInputid,
  k_imageid,
  k_labelid,
  k_buttonid,
  k_screenid,
  k_dataid,
  k_customContainer,
  k_radioButtonid,
  k_iconid,
  k_dateTimeid,
  k_switchid,
} from "../constants";

var whenDoScreen = {
  type: "when_do_screen",
  message0: "when %1 opens do %2",
  args0: [
    {
      type: "input_dummy",
      name: "current_screen_input",
    },
    {
      type: "input_statement",
      name: "do",
    },
  ],
  style: {
    hat: "caps",
  },
  nextStatement: null,
  colour: 180,
  tooltip: "Choose what should happen when the screen loads",
  helpUrl: "",
  extensions: ["get_current_screen"],
};

var getterScreenBlock = {
  type: "al_screen_getter",
  message0: "%1 %2 get %3",
  args0: [
    {
      type: "field_label_serializable",
      name: "from",
      text: "from",
    },
    {
      type: "input_dummy",
      name: "current_screen_input",
    },
    {
      type: "field_dropdown",
      name: "screen_properties",
      options: [
        ["Background color", "backgroundColor"],
        ["Align items vertically", "justifyContent"],
        ["Align items horizontally", "alignItems"],
      ],
    },
  ],
  extensions: ["get_current_screen"],
  inputsInline: false,
  output: null,
  colour: 60,
  tooltip: "Get Screen properties",
  helpUrl: "",
};

var setterScreenBlock = {
  type: "al_screen_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "current_screen_input",
    },
    {
      type: "field_dropdown",
      name: "screen_properties",
      options: [
        ["Background color", "backgroundColor"],
        ["Align items vertically", "justifyContent"],
        ["Align items horizontally", "alignItems"],
      ],
    },
    {
      type: "input_value",
      name: "set screen",
      align: "RIGHT",
    },
  ],
  extensions: ["get_current_screen"],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 60,
  tooltip: "Set Screen properties",
  helpUrl: "",
};

var navigateToAnotherScreen = {
  type: "al_move_screen",
  message0: "move to %1",
  args0: [
    {
      type: "input_dummy",
      name: "screen_input",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_screens"],
  colour: 225,
  tooltip: "Use to move to a particular screen on an event",
  helpUrl: "",
};

var whenDoButtons = {
  type: "when_do_buttons",
  message0: "when %1 click do %2",
  args0: [
    {
      type: "input_dummy",
      name: "button_input",
    },
    {
      type: "input_statement",
      name: "do",
    },
  ],
  extensions: ["get_buttons"],
  style: {
    hat: "cap",
  },
  nextStatement: null,
  colour: 150,
  tooltip: "Choose the button and its actions",
  helpUrl: "",
};

var getterBlockButton = {
  type: "al_button_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "button_input",
    },
    {
      type: "field_dropdown",
      name: "button_properties",
      options: [
        ["Background Color", "backgroundColor"],
        ["Text", "textContent"],
        ["Text Color", "color"],
        ["Boldness", "fontWeight"],
        ["Font Style", "fontStyle"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
      ],
    },
  ],
  extensions: ["get_buttons"],
  inputsInline: false,
  output: null,
  colour: 150,
  tooltip: "Get button properties",
  helpUrl: "",
};

var setterBlockButton = {
  type: "al_button_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "button_input",
    },
    {
      type: "field_dropdown",
      name: "button_properties",
      options: [
        ["Background Color", "backgroundColor"],
        ["Text", "textContent"],
        ["Text Color", "color"],
        ["Boldness", "fontWeight"],
        ["Font Style", "fontStyle"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
      ],
    },
    {
      type: "input_value",
      name: "set button",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  extensions: ["get_buttons"],
  previousStatement: null,
  nextStatement: null,
  colour: 150,
  tooltip: "Set button properties",
  helpUrl: "",
};

var labelGetterBlock = {
  type: "al_label_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "label_input",
    },
    {
      type: "field_dropdown",
      name: "label_properties",
      options: [
        ["Background Color", "backgroundColor"],
        ["Text", "textContent"],
        ["Text Color", "color"],
        ["Boldness", "fontWeight"],
        ["Font Style", "fontStyle"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_label"],
  colour: 30,
  tooltip: "Get label properties",
  helpUrl: "",
};

var labelSetterBlock = {
  type: "al_label_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "label_input",
    },
    {
      type: "field_dropdown",
      name: "label_properties",
      options: [
        ["Background Color", "backgroundColor"],
        ["Text", "textContent"],
        ["Text Color", "color"],
        ["Boldness", "fontWeight"],
        ["Font Style", "fontStyle"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
      ],
    },
    {
      type: "input_value",
      name: "set label",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_label"],
  colour: 30,
  tooltip: "Set label properties",
  helpUrl: "",
};

var textInputChangeBlock = {
  type: "al_text_input_change",
  message0: "when %1 %2 do %3",
  args0: [
    {
      type: "input_dummy",
      name: "textInput_input",
    },
    {
      type: "field_dropdown",
      name: "event_type",
      options: [
        ["changes", "change"],
        ["instant change", "keyup"],
      ],
    },
    {
      type: "input_statement",
      name: "action",
    },
  ],
  nextStatement: null,
  extensions: ["get_textInput"],
  colour: 0,
  tooltip: "When text input changes do these blocks",
  helpUrl: "",
};

var textInputGetterBlock = {
  type: "al_text_input_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "textInput_input",
    },
    {
      type: "field_dropdown",
      name: "text_input_properties",
      options: [
        ["Value", "value"],
        ["Name", "name"],
        ["Hint text", "placeholder"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
        ["keyboard type", "type"],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_textInput"],
  colour: 0,
  tooltip: "Get text input properties",
  helpUrl: "",
};

var textInputSetterBlock = {
  type: "al_text_input_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "textInput_input",
    },
    {
      type: "field_dropdown",
      name: "text_input_properties",
      options: [
        ["Value", "value"],
        ["Hint text", "placeholder"],
        ["Height", "height"],
        ["Width", "width"],
        ["Visible", "display"],
        ["keyboard type", "type"],
      ],
    },
    {
      type: "input_value",
      name: "set text input",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_textInput"],
  colour: 0,
  tooltip: "Set text input properties",
  helpUrl: "",
};

var imageGetterBlock = {
  type: "al_image_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "image_input",
    },
    {
      type: "field_dropdown",
      name: "image_properties",
      options: [
        ["Height", "height"],
        ["Width", "width"],
        ["Resize", "objectFit"],
        ["Visible", "display"],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_images"],
  colour: 270,
  tooltip: "Get image input properties",
  helpUrl: "",
};

var imageSetterBlock = {
  type: "al_image_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "image_input",
    },
    {
      type: "field_dropdown",
      name: "image_properties",
      options: [
        ["Height", "height"],
        ["Width", "width"],
        ["Resize", "objectFit"],
        ["Visible", "display"],
      ],
    },
    {
      type: "input_value",
      name: "set image",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_images"],
  colour: 270,
  tooltip: "Set image input properties",
  helpUrl: "",
};

var radioButtonGetterBlock = {
  type: "al_radio_button_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "radio_button_input",
    },
    {
      type: "field_dropdown",
      name: "radio_button_properties",
      options: [
        ["Selected", "checked"],
        ["Label", "textContent"],
        ["Label position", "flexDirection"],
        ["Visible", "display"],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_radio_buttons"],
  colour: 300,
  tooltip: "Get radio button input properties",
  helpUrl: "",
};

var radioButtonSetterBlock = {
  type: "al_radio_button_setter",
  message0: "for %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "radio_button_input",
    },
    {
      type: "field_dropdown",
      name: "radio_button_properties",
      options: [
        ["Selected", "checked"],
        ["Label", "textContent"],
        ["Visible", "display"],
      ],
    },
    {
      type: "input_value",
      name: "set radio button",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_radio_buttons"],
  colour: 300,
  tooltip: "Set radio button input properties",
  helpUrl: "",
};

var radioButtongetSelectedGroupValue = {
  type: "al_radio_button_get_selected",
  message0: "get selected value of %1's group",
  args0: [
    {
      type: "input_dummy",
      name: "radio_button_input",
    },
  ],
  inputsInline: true,
  output: null,
  extensions: ["get_radio_buttons_groups"],
  colour: 300,
  tooltip: "Get selected value of this radio button's group",
  helpUrl: "",
};

var radioButtonSetGroupValue = {
  type: "al_radio_button_group_set",
  message0: "set group value for %1 to %2",
  args0: [
    {
      type: "input_dummy",
      name: "radio_button_input",
    },
    {
      type: "input_value",
      name: "set group value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_radio_buttons_groups"],
  colour: 300,
  tooltip: "Set group value for radio button",
  helpUrl: "",
};

var iconEvent = {
  type: "al_icon_event",
  message0: "when %1 %2 do %3",
  args0: [
    {
      type: "input_dummy",
      name: "icon_input",
    },
    {
      type: "field_dropdown",
      name: "event_type",
      options: [["clicked", "click"]],
    },
    {
      type: "input_statement",
      name: "action",
    },
  ],
  nextStatement: null,
  extensions: ["get_icon"],
  colour: 300,
  tooltip: "When icon clicked do these blocks",
  helpUrl: "",
};

// var iconGetterBlock = {
//   type: "al_icon_getter",
//   message0: "from %1 get %2",
//   args0: [
//     {
//       type: "input_dummy",
//       name: "icon_input",
//     },
//     {
//       type: "field_dropdown",
//       name: "icon_properties",
//       options: [["helper text", "title"]],
//     },
//   ],
//   inputsInline: false,
//   output: null,
//   extensions: ["get_icon"],
//   colour: 300,
//   tooltip: "Get icon properties",
//   helpUrl: "",
// };

var datetimeGetterBlock = {
  type: "al_datetime_input_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "datetime_input",
    },
    {
      type: "field_dropdown",
      name: "datetime_properties",
      options: [
        ["mode", "type"],
        ["value", "value"],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_datetime"],
  colour: 120,
  tooltip: "Get date time properties",
  helpUrl: "",
};

var datetimeSetterBlock = {
  type: "al_datetime_input_setter",
  message0: "from %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "datetime_input",
    },
    {
      type: "field_dropdown",
      name: "datetime_properties",
      options: [["value", "value"]],
    },
    {
      type: "input_value",
      name: "set value",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_datetime"],
  colour: 120,
  tooltip: "Set date time properties",
  helpUrl: "",
};

var datetimeEventBlock = {
  type: "al_datetime_event",
  message0: "when %1 is %2 do %3",
  args0: [
    {
      type: "input_dummy",
      name: "datetime_input",
    },
    {
      type: "field_dropdown",
      name: "event_type",
      options: [["changed", "change"]],
    },
    {
      type: "input_statement",
      name: "action",
    },
  ],
  nextStatement: null,
  extensions: ["get_datetime"],
  colour: 120,
  tooltip: "When date time input changes do these blocks",
  helpUrl: "",
};

var switchGetterBlock = {
  type: "al_switch_input_getter",
  message0: "from %1 get %2",
  args0: [
    {
      type: "input_dummy",
      name: "switch_input",
    },
    {
      type: "field_dropdown",
      name: "switch_properties",
      options: [["selected", "checked"]],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ["get_switch"],
  colour: 240,
  tooltip: "Get switch properties",
  helpUrl: "",
};

var switchSetterBlock = {
  type: "al_switch_input_setter",
  message0: "from %1 set %2 to %3",
  args0: [
    {
      type: "input_dummy",
      name: "switch_input",
    },
    {
      type: "field_dropdown",
      name: "switch_properties",
      options: [["selected", "checked"]],
    },
    {
      type: "input_value",
      name: "set value",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ["get_switch"],
  colour: 240,
  tooltip: "Set switch properties",
  helpUrl: "",
};

var switchEventBlock = {
  type: "al_switch_event",
  message0: "when %1 is %2 do %3",
  args0: [
    {
      type: "input_dummy",
      name: "switch_input",
    },
    {
      type: "field_dropdown",
      name: "event_type",
      options: [
        ["changed", "change"],
        ["clicked", "click"],
      ],
    },
    {
      type: "input_statement",
      name: "action",
    },
  ],
  nextStatement: null,
  extensions: ["get_switch"],
  colour: 240,
  tooltip: "When switch changes do these blocks",
  helpUrl: "",
};

var setColor = {
  type: "set_color",
  message0: "%1",
  args0: [
    {
      type: "field_colour",
      name: "COLOR",
      colour: "#ff0000",
    },
  ],
  output: "Colour",
  colour: 210,
  tooltip: "",
  helpUrl: "",
};

var setTextInput = {
  type: "set_text_input",
  message0: '" %1 "',
  args0: [
    {
      type: "field_input",
      name: "NAME",
      text: "type here",
    },
  ],
  output: "String",
  colour: 210,
  tooltip: "",
  helpUrl: "",
};

var setNumberInput = {
  type: "al_numerical_input",
  message0: "%1",
  args0: [
    {
      type: "field_number",
      name: "number_input",
      value: 0,
    },
  ],
  output: "Number",
  colour: 210,
  tooltip: "",
  helpUrl: "",
};

var setBooleanInput = {
  type: "al_boolean",
  message0: "%1",
  args0: [
    {
      type: "field_dropdown",
      name: "boolean",
      options: [
        ["true", "true"],
        ["false", "false"],
      ],
    },
  ],
  output: "Boolean",
  colour: 210,
  tooltip: "",
  helpUrl: "",
};

var setKeyboardType = {
  type: "al_keyboard_type_input",
  message0: "%1 %2",
  args0: [
    {
      type: "field_label_serializable",
      name: "keyboard_type",
      text: "keyboard type",
    },
    {
      type: "field_dropdown",
      name: "keyboard_type_options",
      options: [
        ["default", "text"],
        ["email address", "email"],
        ["numerical", "number"],
        ["*masked*", "password"],
      ],
    },
  ],
  output: "String",
  colour: 0,
  tooltip: "",
  helpUrl: "",
};

var setScreenJustification = {
  type: "al_screen_justification",
  message0: "justification %1",
  args0: [
    {
      type: "field_dropdown",
      name: "justification_options",
      options: [
        ["top", "flex-start"],
        ["bottom", "flex-end"],
        ["center", "center"],
      ],
    },
  ],
  output: "String",
  colour: 60,
  tooltip: "Used to set justification",
  helpUrl: "",
};

var setScreenAlignment = {
  type: "al_screen_alignment",
  message0: "alignment %1",
  args0: [
    {
      type: "field_dropdown",
      name: "alignment_options",
      options: [
        ["left", "flex-start"],
        ["right", "flex-end"],
        ["center", "center"],
      ],
    },
  ],
  output: "String",
  colour: 60,
  tooltip: "Used to set alignment",
  helpUrl: "",
};

var setImageSize = {
  type: "al_image_size",
  message0: "resize %1",
  args0: [
    {
      type: "field_dropdown",
      name: "image_resize",
      options: [
        ["cover", "cover"],
        ["contain", "contain"],
        ["fill", "fill"],
        ["none", "none"],
      ],
    },
  ],
  output: "String",
  colour: 270,
  tooltip: "Used to set image resize",
  helpUrl: "",
};

var insertTableRow = {
  type: "al_insert_table_row",
  message0:
    "in table %1 set value for column %2 for row number %3 get value from %4",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "row_number",
    },
    {
      type: "input_value",
      name: "get_value_from",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 90,
  tooltip: "Inserts value in the given column in a table",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "insert_value_table_mutator_mixin",
};

var insertTableRowEnd = {
  type: "al_insert_table_row_end",
  message0: "Add a row in the end of the table %1 %2",
  args0: [
    {
      // for the purpose of breaking line
      type: "input_dummy",
    },
    {
      type: "input_dummy",
      name: "table_input",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 90,
  tooltip: "Inserts new row at the end of the table",
  helpUrl: "",
  extensions: ["get_tables"],
  mutator: "update_column_value_fields",
};

var updateTableData = {
  type: "al_update_table_data",
  message0:
    "Update in table %1 where column %2 is %3 set value for column %4 to %5",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "get_value",
    },
    {
      type: "input_dummy",
      name: "column_input1",
    },
    {
      type: "input_value",
      name: "set_value",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 120,
  tooltip: "Update value in a table",
  helpUrl: "",
  extensions: ["get_tables", "get_column", "get_column1"],
  mutator: "update_table_mutator_mixin",
};

var updateTableAllData = {
  type: "al_update_table_all_data",
  message0:
    "Update all in table %1 where column %2 is %3 set value for column %4 to %5",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "get_value",
    },
    {
      type: "input_dummy",
      name: "column_input1",
    },
    {
      type: "input_value",
      name: "set_value",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 120,
  tooltip: "Update all value in a table",
  helpUrl: "",
  extensions: ["get_tables", "get_column", "get_column1"],
  mutator: "update_table_mutator_mixin",
};

var deleteOneRow = {
  type: "al_delete_one_row",
  message0: "Delete first row from %1 where %2 is %3",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "condition_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: "Delete first row from table where condition is met",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "delete_row_mutator_mixin",
};

var deleteOneRowConditional = {
  type: "al_delete_one_row_conditional",
  message0: "Delete first row from %1 where %2",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_value",
      name: "condition_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: "Delete first row from table where multiple conditions are met",
  helpUrl: "",
  extensions: ["get_tables"],
};

var deleteMultipleRow = {
  type: "al_delete_multiple_row",
  message0: "Delete all rows from %1 where %2 is %3",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "condition_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: "Delete all rows from table where condition is met",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "delete_row_mutator_mixin",
};

var deleteMultipleRowConditional = {
  type: "al_delete_multiple_row_conditional",
  message0: "Delete all rows from %1 where %2",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_value",
      name: "condition_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: "Delete all rows from table where multiple conditions are met",
  helpUrl: "",
  extensions: ["get_tables"],
};

var getTableRow = {
  type: "al_get_table_row",
  message0: "from table %1 get value from column %2 for row number %3",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "row_number",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: "Get value from the given column in a table",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "insert_value_table_mutator_mixin",
};

var getTableRowConditional = {
  type: "al_get_table_row_conditional",
  message0: "from table %1 get first row where column %2 is %3",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "where_value",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: "Get the first row from table where condition is true",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "insert_value_table_mutator_mixin",
};

var getTableRowMultipleConditional = {
  type: "al_get_table_row_multiple_conditional",
  message0: "from table %1 get first row where %2",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_value",
      name: "where_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip:
    "Returns the first row from table where multiple conditions are true",
  helpUrl: "",
  extensions: ["get_tables"],
};

var getTableRowMultipleConditionalMale = {
  type: "al_get_table_row_multiple_conditional_male",
  message0: "from table %1 get row where %2",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_value",
      name: "where_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 140,
  tooltip: "Get the first row from table where multiple conditions are true",
  helpUrl: "",
  extensions: ["get_tables"],
};

var getTableRows = {
  type: "al_get_table_rows",
  message0: "from table %1 get all rows",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: "Get all rows from a table",
  helpUrl: "",
  extensions: ["get_tables"],
};

var getTableRowsConditional = {
  type: "al_get_table_rows_conditional",
  message0: "from table %1 get all rows where %2 is %3",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "input_value",
      name: "where_value",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: "Get all rows from a table where condition is true",
  helpUrl: "",
  extensions: ["get_tables", "get_column"],
  mutator: "insert_value_table_mutator_mixin",
};

var getTableRowsMultipleConditional = {
  type: "al_get_table_rows_multiple_conditional",
  message0: "from table %1 get all rows where %2",
  args0: [
    {
      type: "input_dummy",
      name: "table_input",
    },
    {
      type: "input_value",
      name: "where_value",
      align: "RIGHT",
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: "Get all rows from a table where multiple conditions are true",
  helpUrl: "",
  extensions: ["get_tables"],
};

var whereRowColumn = {
  type: "al_where_row_column",
  message0: "where column name %1 and column value %2",
  args0: [
    {
      type: "input_value",
      name: "column_name",
    },
    {
      type: "input_value",
      name: "column_value",
    },
  ],
  inputsInline: false,
  previousStatement: null,
  colour: 45,
  tooltip: "where clause for a column and row value",
  helpUrl: "",
};

var columnCondition = {
  type: "al_column_condition",
  message0: "%1 %2 %3",
  args0: [
    {
      type: "input_dummy",
      name: "column_input",
    },
    {
      type: "field_dropdown",
      name: "comparison_operator",
      options: [
        ["is equal to", "=="],
        ["is not equal to", "!="],
        ["is greater than", ">"],
        ["is greater than or equal to", ">="],
        ["is less than", "<"],
        ["is less than or equal to", "<="],
      ],
    },
    {
      type: "input_value",
      name: "condition_value",
    },
  ],
  inputsInline: false,
  output: "Boolean",
  colour: 45,
  tooltip: "where clause for a column",
  helpUrl: "",
  extensions: ["get_column_from_parent_block"],
  mutator: "column_condition_mutator_mixin",
};

var nextRow = {
  type: "al_next_row",
  message0: "next row",
  inputsInline: false,
  output: null,
  colour: 45,
  tooltip: "where clause for a column and row value",
  helpUrl: "",
};

// var customContainerParent_getAllRows = {
//     "type": "al_custome_container_selector",
//     "message0": "In container %1, get all rows from table %2",
//     "args0": [
//         {
//             "type": "input_dummy",
//             "name": "container_input"
//         },
//         {
//             "type": "input_dummy",
//             "name": "table_input"
//         }
//     ],
//     "nextStatement": null,
//     "colour": 45,
//     "tooltip": "Choose the button and its actions",
//     "helpUrl": "",
//     "extensions": ["get_tables", "get_custom_container"],
//     "mutator": "update_column_value_fields"
// }

// var createNewComponent = {
//     "type": "al_create_new_component",
//     "message0": "create a new component of type %1",
//     "args0": [
//         {
//             "type": "field_dropdown",
//             "name": "component_type",
//             "options": [
//                 [
//                     "label",
//                     "label"
//                 ]
//             ]
//         }
//     ],
//     "inputsInline": false,
//     "previousStatement": null,
//     "colour": 45,
//     "tooltip": "Create a new component inside the container component",
//     "helpUrl": ""
// }

// Extensions

Blockly.Extensions.register("get_current_screen", function () {
  this.getInput("current_screen_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter(
          (comp) => comp._id === localStorage.getItem("applab_currentScreen")
        )
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "current_screen_input"
  );
});

Blockly.Extensions.register("get_screens", function () {
  this.getInput("screen_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_screenid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "screen_input"
  );
});

Blockly.Extensions.register("get_buttons", function () {
  this.getInput("button_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_buttonid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "button_input"
  );
});

Blockly.Extensions.register("get_label", function () {
  this.getInput("label_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_labelid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "label_input"
  );
});

Blockly.Extensions.register("get_textInput", function () {
  this.getInput("textInput_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_textInputid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "textInput_input"
  );
});

Blockly.Extensions.register("get_images", function () {
  this.getInput("image_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_imageid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "image_input"
  );
});

Blockly.Extensions.register("get_radio_buttons", function () {
  this.getInput("radio_button_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_radioButtonid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "radio_button_input"
  );
});

Blockly.Extensions.register("get_radio_buttons_groups", function () {
  this.getInput("radio_button_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_radioButtonid)
        .map((comp) => options.push([comp.name, comp.properties.group.value]));
      return options;
    }),
    "radio_button_input"
  );
});

Blockly.Extensions.register("get_icon", function () {
  this.getInput("icon_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_iconid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "icon_input"
  );
});

Blockly.Extensions.register("get_datetime", function () {
  this.getInput("datetime_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_dateTimeid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "datetime_input"
  );
});

Blockly.Extensions.register("get_switch", function () {
  this.getInput("switch_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_switchid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "switch_input"
  );
});

Blockly.Extensions.register("get_custom_container", function () {
  this.getInput("container_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_customContainer)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "custom_container_field"
  );
});

Blockly.Extensions.register("get_data_components", function () {
  this.getInput("dataC_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let components = JSON.parse(localStorage.getItem("applab_componentList"));
      components
        .filter((comp) => comp.c_type === k_dataid)
        .map((comp) => options.push([comp.name, comp._id]));
      return options;
    }),
    "dataC_input"
  );
});

Blockly.Extensions.register("get_tables", function () {
  this.getInput("table_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let tables = JSON.parse(localStorage.getItem("applab_myTables"));
      tables.map((t) => options.push([t.name, t.id]));
      return options;
    }),
    "table_input_field"
  );
});

Blockly.Extensions.register("get_column", function () {
  var selectedTable = this.getFieldValue("table_input_field");
  this.getInput("column_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
        (table) => table.id === selectedTable
      );
      Object.keys(table["columnData"])
        .filter((cId) => table["columnOrder"].includes(cId))
        .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
      return options;
    }),
    "column_input_field"
  );
});

Blockly.Extensions.register("get_column_from_parent_block", function () {
  var selectedTable;
  if (this.getSurroundParent()) {
    if (this.getSurroundParent().getFieldValue("table_input_field")) {
      // console.log('parent block is a data block. table id is ', this.getSurroundParent().getFieldValue("table_input_field"))
      selectedTable =
        this.getSurroundParent().getFieldValue("table_input_field");
    } else if (this.getSurroundParent().getSurroundParent()) {
      if (
        this.getSurroundParent()
          .getSurroundParent()
          .getFieldValue("table_input_field")
      ) {
        // console.log('parent block is a logic block. table id is ', this.getSurroundParent().getSurroundParent().getFieldValue("table_input_field"))
        selectedTable = this.getSurroundParent()
          .getSurroundParent()
          .getFieldValue("table_input_field");
      }
    }
  }
  this.getInput("column_input").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      if (selectedTable) {
        let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
          (table) => table.id === selectedTable
        );
        Object.keys(table["columnData"])
          .filter((cId) => table["columnOrder"].includes(cId))
          .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
      } else {
        options.push(["please select a table", "invalid"]);
      }
      return options;
    }),
    "column_input_field"
  );
});

Blockly.Extensions.register("get_column1", function () {
  var selectedTable = this.getFieldValue("table_input_field");
  var selectedColumn = this.getFieldValue("column_input_field");
  this.getInput("column_input1").appendField(
    new Blockly.FieldDropdown(function () {
      let options = [];
      let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
        (table) => table.id === selectedTable
      );
      Object.keys(table["columnData"])
        .filter(
          (cId) => table["columnOrder"].includes(cId) && cId !== selectedColumn
        )
        .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
      return options;
    }),
    "column_input1_field"
  );
});

Blockly.Extensions.registerMixin("insert_value_table_mutator_mixin", {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");
    container.setAttribute(
      "update_value_column_tableId",
      this.getFieldValue("table_input_field")
    );
    container.setAttribute(
      "update_value_column_columnId",
      this.getFieldValue("column_input_field")
    );
    return container;
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    this.setFieldValue(
      xmlElement.getAttribute("update_value_column_tableId"),
      "table_input_field"
    );
    // update column drop down
    this.getInput("column_input").removeField("column_input_field");
    this.getInput("column_input").appendField(
      new Blockly.FieldDropdown(function () {
        let options = [];
        let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
          (table) =>
            table.id === xmlElement.getAttribute("update_value_column_tableId")
        );
        Object.keys(table["columnData"])
          .filter((cId) => table["columnOrder"].includes(cId))
          .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
        return options;
      }),
      "column_input_field"
    );
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute("update_value_column_columnId"),
      "column_input_field"
    );
  },
});

Blockly.Extensions.registerMixin("update_column_value_fields", {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");
    container.setAttribute(
      "add_new_row_end_tableId",
      this.getFieldValue("table_input_field")
    );
    return container;
  },
  domToMutation: function (xmlElement) {
    this.setFieldValue(
      xmlElement.getAttribute("add_new_row_end_tableId"),
      "table_input_field"
    );
    // this.removeInput('column_input')
    let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
      (t) => t.id === xmlElement.getAttribute("add_new_row_end_tableId")
    );
    table["columnOrder"].map((cId) => {
      let columnname = table["columnData"][cId].name;
      this.appendValueInput("column_input" + cId)
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(columnname.toString() + " value as");
      return null;
    });
  },
});

Blockly.Extensions.registerMixin("update_table_mutator_mixin", {
  mutationToDom: function () {
    // store table id in mutator when serializing
    const container = Blockly.utils.xml.createElement("mutation");
    container.setAttribute("tableId", this.getFieldValue("table_input_field"));
    container.setAttribute(
      "columnId",
      this.getFieldValue("column_input_field")
    );
    container.setAttribute(
      "column1Id",
      this.getFieldValue("column_input1_field")
    );
    return container;
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    this.setFieldValue(xmlElement.getAttribute("tableId"), "table_input_field");
    // update column drop down
    this.getInput("column_input").removeField("column_input_field");
    this.getInput("column_input").appendField(
      new Blockly.FieldDropdown(function () {
        let options = [];
        let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
          (table) => table.id === xmlElement.getAttribute("tableId")
        );
        Object.keys(table["columnData"])
          .filter((cId) => table["columnOrder"].includes(cId))
          .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
        return options;
      }),
      "column_input_field"
    );
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute("columnId"),
      "column_input_field"
    );
    // update column1 drop down
    this.getInput("column_input1").removeField("column_input1_field");
    this.getInput("column_input1").appendField(
      new Blockly.FieldDropdown(function () {
        let options = [];
        let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
          (table) => table.id === xmlElement.getAttribute("tableId")
        );
        Object.keys(table["columnData"])
          .filter(
            (cId) =>
              table["columnOrder"].includes(cId) &&
              cId !== xmlElement.getAttribute("columnId")
          )
          .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
        return options;
      }),
      "column_input1_field"
    );
    // set value for column1 drop down
    this.setFieldValue(
      xmlElement.getAttribute("column1Id"),
      "column_input1_field"
    );
  },
});

Blockly.Extensions.registerMixin("column_condition_mutator_mixin", {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");
    if (this.getSurroundParent()) {
      if (this.getSurroundParent().getFieldValue("table_input_field")) {
        // console.log('parent block is a data block. table id is ', this.getSurroundParent().getFieldValue("table_input_field"))
        container.setAttribute(
          "condition_column_parent_tableId",
          this.getSurroundParent().getFieldValue("table_input_field")
        );
      } else if (this.getSurroundParent().getSurroundParent()) {
        if (
          this.getSurroundParent()
            .getSurroundParent()
            .getFieldValue("table_input_field")
        ) {
          // console.log('parent block is a logic block. table id is ', this.getSurroundParent().getSurroundParent().getFieldValue("table_input_field"))
          container.setAttribute(
            "condition_column_parent_tableId",
            this.getSurroundParent()
              .getSurroundParent()
              .getFieldValue("table_input_field")
          );
        }
      }
      // console.log(this.getFieldValue('column_input_field'));
      // container.setAttribute('condition_column_parent_tableId', this.getSurroundParent().getFieldValue('table_input_field'));
      container.setAttribute(
        "condition_column_columnId",
        this.getFieldValue("column_input_field")
      );
    }
    return container;
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    let tableId = xmlElement.getAttribute("condition_column_parent_tableId");
    // update column drop down
    this.getInput("column_input").removeField("column_input_field");
    if (tableId) {
      this.getInput("column_input").appendField(
        new Blockly.FieldDropdown(function () {
          let options = [];
          let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
            (table) => table.id === tableId
          );
          Object.keys(table["columnData"])
            .filter((cId) => table["columnOrder"].includes(cId))
            .map((cId) =>
              options.push([table["columnData"][cId]["name"], cId])
            );
          return options;
        }),
        "column_input_field"
      );
    } else {
      this.getInput("column_input").appendField(
        new Blockly.FieldDropdown(function () {
          return [["please select a table", "invalid"]];
        }),
        "column_input_field"
      );
    }
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute("condition_column_columnId"),
      "column_input_field"
    );
  },
});

Blockly.Extensions.registerMixin("delete_row_mutator_mixin", {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement("mutation");
    container.setAttribute(
      "delete_row_tableId",
      this.getFieldValue("table_input_field")
    );
    container.setAttribute(
      "delete_row_columnId",
      this.getFieldValue("column_input_field")
    );
    return container;
  },
  domToMutation: function (xmlElement) {
    this.setFieldValue(
      xmlElement.getAttribute("delete_row_tableId"),
      "table_input_field"
    );
    this.getInput("column_input").removeField("column_input_field");
    this.getInput("column_input").appendField(
      new Blockly.FieldDropdown(function () {
        let options = [];
        let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
          (table) => table.id === xmlElement.getAttribute("delete_row_tableId")
        );
        Object.keys(table["columnData"])
          .filter((cId) => table["columnOrder"].includes(cId))
          .map((cId) => options.push([table["columnData"][cId]["name"], cId]));
        return options;
      }),
      "column_input_field"
    );
    this.setFieldValue(
      xmlElement.getAttribute("delete_row_columnId"),
      "column_input_field"
    );
  },
});

// Init blocks

Blockly.Blocks["when_do_screen"] = {
  init: function () {
    this.jsonInit(whenDoScreen);
  },
};

Blockly.Blocks["al_screen_getter"] = {
  init: function () {
    this.jsonInit(getterScreenBlock);
  },
};

Blockly.Blocks["al_screen_setter"] = {
  init: function () {
    this.jsonInit(setterScreenBlock);
  },
};

Blockly.Blocks["al_move_screen"] = {
  init: function () {
    this.jsonInit(navigateToAnotherScreen);
  },
};

Blockly.Blocks["when_do_buttons"] = {
  init: function () {
    this.jsonInit(whenDoButtons);
  },
};

Blockly.Blocks["al_button_getter"] = {
  init: function () {
    this.jsonInit(getterBlockButton);
  },
};

Blockly.Blocks["al_button_setter"] = {
  init: function () {
    this.jsonInit(setterBlockButton);
  },
};

Blockly.Blocks["al_label_getter"] = {
  init: function () {
    this.jsonInit(labelGetterBlock);
  },
};

Blockly.Blocks["al_label_setter"] = {
  init: function () {
    this.jsonInit(labelSetterBlock);
  },
};

Blockly.Blocks["al_text_input_change"] = {
  init: function () {
    this.jsonInit(textInputChangeBlock);
  },
};

Blockly.Blocks["al_text_input_getter"] = {
  init: function () {
    this.jsonInit(textInputGetterBlock);
  },
};

Blockly.Blocks["al_text_input_setter"] = {
  init: function () {
    this.jsonInit(textInputSetterBlock);
  },
};

Blockly.Blocks["al_image_getter"] = {
  init: function () {
    this.jsonInit(imageGetterBlock);
  },
};

Blockly.Blocks["al_image_setter"] = {
  init: function () {
    this.jsonInit(imageSetterBlock);
  },
};

Blockly.Blocks["al_radio_button_getter"] = {
  init: function () {
    this.jsonInit(radioButtonGetterBlock);
  },
};

Blockly.Blocks["al_radio_button_setter"] = {
  init: function () {
    this.jsonInit(radioButtonSetterBlock);
  },
};

Blockly.Blocks["al_radio_button_get_selected"] = {
  init: function () {
    this.jsonInit(radioButtongetSelectedGroupValue);
  },
};

Blockly.Blocks["al_radio_button_group_set"] = {
  init: function () {
    this.jsonInit(radioButtonSetGroupValue);
  },
};

Blockly.Blocks["al_icon_event"] = {
  init: function () {
    this.jsonInit(iconEvent);
  },
};

// Blockly.Blocks["al_icon_getter"] = {
//   init: function () {
//     this.jsonInit(iconGetterBlock);
//   },
// };

Blockly.Blocks["al_datetime_input_getter"] = {
  init: function () {
    this.jsonInit(datetimeGetterBlock);
  },
};

Blockly.Blocks["al_datetime_input_setter"] = {
  init: function () {
    this.jsonInit(datetimeSetterBlock);
  },
};

Blockly.Blocks["al_datetime_event"] = {
  init: function () {
    this.jsonInit(datetimeEventBlock);
  },
};

Blockly.Blocks["al_switch_input_getter"] = {
  init: function () {
    this.jsonInit(switchGetterBlock);
  },
};

Blockly.Blocks["al_switch_input_setter"] = {
  init: function () {
    this.jsonInit(switchSetterBlock);
  },
};

Blockly.Blocks["al_switch_event"] = {
  init: function () {
    this.jsonInit(switchEventBlock);
  },
};

Blockly.Blocks["set_color"] = {
  init: function () {
    this.jsonInit(setColor);
  },
};

Blockly.Blocks["set_text_input"] = {
  init: function () {
    this.jsonInit(setTextInput);
  },
};

Blockly.Blocks["al_numerical_input"] = {
  init: function () {
    this.jsonInit(setNumberInput);
  },
};

Blockly.Blocks["al_boolean"] = {
  init: function () {
    this.jsonInit(setBooleanInput);
  },
};

Blockly.Blocks["al_keyboard_type_input"] = {
  init: function () {
    this.jsonInit(setKeyboardType);
  },
};

Blockly.Blocks["al_screen_justification"] = {
  init: function () {
    this.jsonInit(setScreenJustification);
  },
};

Blockly.Blocks["al_screen_alignment"] = {
  init: function () {
    this.jsonInit(setScreenAlignment);
  },
};

Blockly.Blocks["al_image_size"] = {
  init: function () {
    this.jsonInit(setImageSize);
  },
};

Blockly.Blocks["al_insert_table_row"] = {
  init: function () {
    this.jsonInit(insertTableRow);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_insert_table_row_end"] = {
  init: function () {
    this.jsonInit(insertTableRowEnd);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === "table_input_field") {
          let oldTable = JSON.parse(
            localStorage.getItem("applab_myTables")
          ).find((t) => t.id === event.oldValue);
          oldTable["columnOrder"].map((cId) =>
            this.removeInput("column_input" + cId)
          );
          let table = JSON.parse(localStorage.getItem("applab_myTables")).find(
            (t) => t.id === event.newValue
          );
          table["columnOrder"].map((cId) => {
            let columnname = table["columnData"][cId].name;
            this.appendValueInput("column_input" + cId)
              .setAlign(Blockly.ALIGN_RIGHT)
              .appendField(columnname.toString() + " value as");
            return null;
          });
        }
      }
    }
  },
};

Blockly.Blocks["al_update_table_data"] = {
  init: function () {
    this.jsonInit(updateTableData);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
          var selectedColumn = this.getFieldValue("column_input_field");
          this.getInput("column_input1").removeField("column_input1_field");
          this.getInput("column_input1").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .filter((cId) => cId !== selectedColumn)
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input1_field"
          );
        } else if (event.name === "column_input_field") {
          // change in selected column, update column_input1
          var table_id = this.getFieldValue("table_input_field");
          this.getInput("column_input1").removeField("column_input1_field");
          this.getInput("column_input1").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === table_id);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .filter((cId) => cId !== event.newValue)
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input1_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_update_table_all_data"] = {
  init: function () {
    this.jsonInit(updateTableAllData);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
          var selectedColumn = this.getFieldValue("column_input_field");
          this.getInput("column_input1").removeField("column_input1_field");
          this.getInput("column_input1").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .filter((cId) => cId !== selectedColumn)
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input1_field"
          );
        } else if (event.name === "column_input_field") {
          // change in selected column, update column_input1
          var table_id = this.getFieldValue("table_input_field");
          this.getInput("column_input1").removeField("column_input1_field");
          this.getInput("column_input1").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === table_id);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .filter((cId) => cId !== event.newValue)
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input1_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_delete_one_row"] = {
  init: function () {
    this.jsonInit(deleteOneRow);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_delete_one_row_conditional"] = {
  init: function () {
    this.jsonInit(deleteOneRowConditional);
  },
};

Blockly.Blocks["al_delete_multiple_row"] = {
  init: function () {
    this.jsonInit(deleteMultipleRow);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_delete_multiple_row_conditional"] = {
  init: function () {
    this.jsonInit(deleteMultipleRowConditional);
  },
};

Blockly.Blocks["al_get_table_row"] = {
  init: function () {
    this.jsonInit(getTableRow);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_get_table_row_conditional"] = {
  init: function () {
    this.jsonInit(getTableRowConditional);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_get_table_row_multiple_conditional"] = {
  init: function () {
    this.jsonInit(getTableRowMultipleConditional);
  },
};

Blockly.Blocks["al_get_table_row_multiple_conditional_male"] = {
  init: function () {
    this.jsonInit(getTableRowMultipleConditionalMale);
  },
};

Blockly.Blocks["al_get_table_rows"] = {
  init: function () {
    this.jsonInit(getTableRows);
  },
};

Blockly.Blocks["al_get_table_rows_conditional"] = {
  init: function () {
    this.jsonInit(getTableRowsConditional);
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === "table_input_field") {
          // change in table, update column_input and column_input1
          this.getInput("column_input").removeField("column_input_field");
          this.getInput("column_input").appendField(
            new Blockly.FieldDropdown(function () {
              let options = [];
              let table = JSON.parse(
                localStorage.getItem("applab_myTables")
              ).find((table) => table.id === event.newValue);
              Object.keys(table["columnData"])
                .filter((cId) => table["columnOrder"].includes(cId))
                .map((cId) =>
                  options.push([table["columnData"][cId]["name"], cId])
                );
              return options;
            }),
            "column_input_field"
          );
        }
      }
    }
  },
};

Blockly.Blocks["al_get_table_rows_multiple_conditional"] = {
  init: function () {
    this.jsonInit(getTableRowsMultipleConditional);
  },
};

Blockly.Blocks["al_where_row_column"] = {
  init: function () {
    this.jsonInit(whereRowColumn);
  },
};

Blockly.Blocks["al_column_condition"] = {
  init: function () {
    this.jsonInit(columnCondition);
  },
  onchange: function (event) {
    // check if change happened to a surroundParent with table_input_field
    let tableBlockId = undefined,
      tableId = undefined,
      parentBlockId = undefined;
    if (
      this.getSurroundParent() &&
      this.getSurroundParent().getFieldValue("table_input_field")
    ) {
      tableId = this.getSurroundParent().getFieldValue("table_input_field");
      tableBlockId = this.getSurroundParent().id;
    } else if (
      this.getSurroundParent() &&
      this.getSurroundParent().getSurroundParent() &&
      this.getSurroundParent()
        .getSurroundParent()
        .getFieldValue("table_input_field")
    ) {
      tableId = this.getSurroundParent()
        .getSurroundParent()
        .getFieldValue("table_input_field");
      parentBlockId = this.getSurroundParent().id;
      tableBlockId = this.getSurroundParent().getSurroundParent().id;
    }
    if (
      event.blockId === tableBlockId &&
      event.type === Blockly.Events.BLOCK_CHANGE
    ) {
      // change taken place to table block
      // event type is change
      if (event.name === "table_input_field") {
        // change happened to table drop down
        this.getInput("column_input").removeField("column_input_field");
        this.getInput("column_input").appendField(
          new Blockly.FieldDropdown(function () {
            let options = [];
            let table = JSON.parse(
              localStorage.getItem("applab_myTables")
            ).find((table) => table.id === event.newValue);
            Object.keys(table["columnData"])
              .filter((cId) => table["columnOrder"].includes(cId))
              .map((cId) =>
                options.push([table["columnData"][cId]["name"], cId])
              );
            return options;
          }),
          "column_input_field"
        );
      }
    }
    if (
      event.blockId === parentBlockId &&
      event.type === Blockly.Events.BLOCK_MOVE
    ) {
      // move change taken place to parent block
      // console.log("parent block moved");
      this.getInput("column_input").removeField("column_input_field");
      if (
        tableId &&
        event.newParentId &&
        event.oldParentId !== event.newParentId
      ) {
        this.getInput("column_input").appendField(
          new Blockly.FieldDropdown(function () {
            let options = [];
            let table = JSON.parse(
              localStorage.getItem("applab_myTables")
            ).find((table) => table.id === tableId);
            Object.keys(table["columnData"])
              .filter((cId) => table["columnOrder"].includes(cId))
              .map((cId) =>
                options.push([table["columnData"][cId]["name"], cId])
              );
            return options;
          }),
          "column_input_field"
        );
      } else {
        this.getInput("column_input").appendField(
          new Blockly.FieldDropdown(function () {
            return [["please select a table", "invalid"]];
          }),
          "column_input_field"
        );
      }
    }
    if (event.blockId === this.id && event.type === Blockly.Events.BLOCK_MOVE) {
      // move change happened to this block
      this.getInput("column_input").removeField("column_input_field");
      if (
        tableId &&
        event.newParentId &&
        event.oldParentId !== event.newParentId
      ) {
        this.getInput("column_input").appendField(
          new Blockly.FieldDropdown(function () {
            let options = [];
            let table = JSON.parse(
              localStorage.getItem("applab_myTables")
            ).find((table) => table.id === tableId);
            Object.keys(table["columnData"])
              .filter((cId) => table["columnOrder"].includes(cId))
              .map((cId) =>
                options.push([table["columnData"][cId]["name"], cId])
              );
            return options;
          }),
          "column_input_field"
        );
      } else {
        this.getInput("column_input").appendField(
          new Blockly.FieldDropdown(function () {
            return [["please select a table", "invalid"]];
          }),
          "column_input_field"
        );
      }
    }
  },
};

Blockly.Blocks["al_next_row"] = {
  init: function () {
    this.jsonInit(nextRow);
  },
};
