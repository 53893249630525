import React, { Component, Fragment } from 'react'
import Cookie from 'js-cookie'
import { HotTable } from '@handsontable/react';
import { FirebaseContext } from '../../components/firebase';
import { Link } from 'react-router-dom';
// components
import { Modal } from '../../components/ui/modal/modal'
import { leftSideNav as LeftSideNav } from '../../components/navigation/leftSideNav/leftSideNav';
// styles
import styles from './Table.module.css'
import 'handsontable/dist/handsontable.full.css';

class Table extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allTableNames: [],
            tableId: null,
            table: {
                name: 'Table name'
            },
            data: [],
            dataSchema: {},
            startRows: 5,
            startCols: 0,
            colHeaders: [],
            columns: [],
            //// edit table
            editTable: {},
            uniqueTableName: null,
        }
        this.userId = null
    }

    componentDidMount() {
        this.userId = Cookie.get('userId')
        this.setState({
            tableId: this.props.match.params.tid,
        })
        let allTables = this.props.firebase.db.ref('/' + this.userId + '/')
        allTables.once('value', snap => {
            let userTables = snap.val() || {}
            let tableName = []
            Object.keys(userTables).map(ut => tableName.push(userTables[ut].name))
            this.setState({
                allTableNames: tableName
            })
        })
        let mytable = this.props.firebase.db.ref('/' + this.userId + '/' + this.props.match.params.tid)
        mytable.on('value', snap => {
            let table = snap.val()
            let data = [], dataSchema = {}, startCols = 0, colHeaders = [], columns = []
            table.columnOrder.map(colId => {
                dataSchema = {
                    ...dataSchema,
                    [colId]: null,
                }
                ++startCols
                colHeaders.push(table.columnData[colId].name)
                columns.push({ data: colId })
                return null
            })
            if ('rowOrder' in table) {
                table.rowOrder.map(rowId => {
                    data.push(table.rowData[rowId])
                    return null
                })
            }
            this.setState({
                table: table,
                dataSchema,
                startCols,
                colHeaders,
                columns,
                data: data,
            })
        })
    }

    beforeChange = (change, source) => {
        // console.log(change)
        if (source === 'edit') { // || source === 'CopyPaste.paste'
            if (!!change[0][3]) {
                let rowData = { [change[0][1]]: change[0][3] }
                if (!(Object.values(this.state.data[change[0][0]]).map(colData => colData).reduce((val, el) => !!val && !!el))) {
                    this.props.firebase.addRow(this.userId, this.state.tableId, change[0][0], rowData)
                } else {
                    this.props.firebase.updateRow(this.userId, this.state.tableId, change[0][0], rowData)
                }
            }
        }
    }

    afterChange = (change, source) => {
        if (source === 'edit') { // || source === 'CopyPaste.paste'
            if (!change[0][3]) {
                let rowData = { [change[0][1]]: change[0][3] }
                if (!(Object.values(this.state.data[change[0][0]]).map(colData => colData).reduce((val, el) => !!val || !!el))) {
                    this.props.firebase.deleteRow(this.userId, this.state.tableId, change[0][0])
                } else {
                    this.props.firebase.updateRow(this.userId, this.state.tableId, change[0][0], rowData)
                }
            }
        }
    }

    beforePaste = (data, coords) => {
        // console.log(data, coords[0])
        let columns = data[0].length - 1
        let rows = data.length - 1
        let startRow = coords[0].startRow
        let fixedStartRow = coords[0].startRow
        let startCol = coords[0].startCol
        let fixedStartCol = coords[0].startCol
        let endCol = startCol + columns
        let endRow = startRow + rows
        // console.log('column', columns, 'row', rows, 'start col', startCol, 'start row', startRow, 'end col', endCol, 'end row', endRow)
        for (startRow = coords[0].startRow; startRow <= endRow; startRow++) {
            // console.log('updating row', startRow)
            for (startCol = coords[0].startCol; startCol <= endCol; startCol++) {
                // console.log('updating column', startCol)
                // console.log(data[startRow - fixedStartRow][startCol - fixedStartCol])
                if (!!data[startRow - fixedStartRow][startCol - fixedStartCol]) {
                    let rowData = { [this.state.columns[startCol].data]: data[startRow - fixedStartRow][startCol - fixedStartCol] }
                    if (!(Object.values(this.state.data[startRow]).map(colData => colData).reduce((val, el) => !!val && !!el))) {
                        this.props.firebase.addRow(this.userId, this.state.tableId, startRow, rowData)
                    } else {
                        this.props.firebase.updateRow(this.userId, this.state.tableId, startRow, rowData)
                    }
                }
            }
        }
    }

    afterPaste = (data, coords) => {
        // console.log(data, coords)
        let columns = data[0].length - 1
        let rows = data.length - 1
        let startRow = coords[0].startRow
        let fixedStartRow = coords[0].startRow
        let startCol = coords[0].startCol
        let fixedStartCol = coords[0].startCol
        let endCol = startCol + columns
        let endRow = startRow + rows
        // console.log('column', columns, 'row', rows, 'start col', startCol, 'start row', startRow, 'end col', endCol, 'end row', endRow)
        for (startRow = coords[0].startRow; startRow <= endRow; startRow++) {
            // console.log('updating row', startRow)
            for (startCol = coords[0].startCol; startCol <= endCol; startCol++) {
                // console.log('updating column', startCol)
                // console.log(data[startRow - fixedStartRow][startCol - fixedStartCol])
                if (!data[startRow - fixedStartRow][startCol - fixedStartCol]) {
                    let rowData = { [this.state.columns[startCol].data]: data[startRow - fixedStartRow][startCol - fixedStartCol] }
                    if (!(Object.values(this.state.data[startRow]).map(colData => colData).reduce((val, el) => !!val || !!el))) {
                        this.props.firebase.deleteRow(this.userId, this.state.tableId, startRow)
                    } else {
                        this.props.firebase.updateRow(this.userId, this.state.tableId, startRow, rowData)
                    }
                }
            }
        }
    }

    onTableEditClick = () => {
        if (Object.keys(this.state.editTable).length === 0) {
            let columnData = {}
            Object.keys(this.state.table.columnData).map(colId => {
                columnData[colId] = { ...this.state.table.columnData[colId], edited: false, removed: false }
                return null
            })
            let editTable = {
                ...this.state.table,
                tableNameEdited: false,
                tableDescriptionEdited: false,
                columnData: columnData
            }
            this.setState({ editTable: editTable })
        } else {
            this.setState({ editTable: {} })
        }
    }

    onEditTableNameChange = e => {
        if (this.state.allTableNames.filter(t => t === e.target.value).length !== 0) {
            this.setState({
                editTable: {
                    ...this.state.editTable,
                    tableNameEdited: true,
                    name: e.target.value
                },
                uniqueTableName: 'Table with such name already exists.'
            })
        } else {
            this.setState({
                editTable: {
                    ...this.state.editTable,
                    tableNameEdited: true,
                    name: e.target.value
                },
                uniqueTableName: null
            })
        }
    }

    onEditTableNameChangeSave = () => {
        this.props.firebase.updateTableName(this.userId, this.state.tableId, this.state.editTable.name)
        this.setState({
            editTable: {
                ...this.state.editTable,
                tableNameEdited: false,
            },
            table: {
                ...this.state.table,
                name: this.state.editTable.name,
            }
        })
    }

    onEditTableNameChangeCancel = () => {
        this.setState({
            editTable: {
                ...this.state.editTable,
                name: this.state.table.name,
                tableNameEdited: false,
            }
        })
    }

    onEditTableColumnAdd = index => {
        let randomNumber = Math.ceil(Math.random() * 1000)
        while ('newColumn' + randomNumber in this.state.editTable.columnData) {
            randomNumber = Math.ceil(Math.random() * 1000)
        }
        let columnOrder = Array.from(this.state.editTable.columnOrder)
        columnOrder.splice(index, 0, 'newColumn' + randomNumber)
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    ['newColumn' + randomNumber]: {
                        name: '',
                        added: true,
                        edited: false,
                        removed: false,
                    }
                },
                columnOrder: columnOrder,
            }
        })
    }

    onEditTableColumnAddSave = colId => {
        let index = this.state.editTable.columnOrder.indexOf(colId)
        let colName = this.state.editTable.columnData[colId].name
        this.props.firebase.addColumn(this.userId, this.state.tableId, index, colName).then(id => {
            let newColumnData = Object.assign(this.state.editTable.columnData)
            newColumnData[id] = newColumnData[colId]
            delete newColumnData[id]['added']
            newColumnData[id]['edited'] = false
            delete newColumnData[colId]
            let newColumnOrder = Array.from(this.state.editTable.columnOrder)
            newColumnOrder.splice(index, 1, id)
            this.setState({
                editTable: {
                    ...this.state.editTable,
                    columnData: newColumnData,
                    columnOrder: newColumnOrder,
                },
            })
        })
    }

    onEditTableColumnAddCancel = columnId => {
        let newColumnData = Object.assign(this.state.editTable.columnData)
        delete newColumnData[columnId]
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: newColumnData,
                columnOrder: this.state.table.columnOrder.filter(c => c !== columnId)
            }
        })
    }

    onEditTableColumnChange = e => {
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    [e.target.name]: {
                        ...this.state.editTable.columnData[e.target.name],
                        edited: true,
                        name: e.target.value
                    }
                }
            }
        })
    }

    onEditTableColumnChangeSave = colId => {
        this.props.firebase.updateColumnName(this.userId, this.state.tableId, colId, this.state.editTable.columnData[colId].name)
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    [colId]: {
                        ...this.state.editTable.columnData[colId],
                        edited: false
                    }
                }
            },
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [colId]: {
                        ...this.state.table.columnData[colId],
                        name: this.state.editTable.columnData[colId].name
                    }
                }
            }
        })
    }

    onEditTableColumnChangeCancel = colId => {
        let prevColumnName = this.state.table.columnData[colId].name
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    [colId]: {
                        ...this.state.editTable.columnData[colId],
                        edited: false,
                        name: prevColumnName
                    }
                }
            }
        })
    }

    onEditTableColumnDelete = colId => {
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    [colId]: {
                        ...this.state.editTable.columnData[colId],
                        removed: true,
                    }
                }
            }
        })
    }

    onEditTableColumnDeleteSave = colId => {
        this.props.firebase.deleteColumn(this.userId, this.state.tableId, colId, this.state.editTable.columnData[colId].name)
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnOrder: this.state.editTable.columnOrder.filter(c => c !== colId)
            },
            table: {
                ...this.state.table,
                columnOrder: this.state.table.columnOrder.filter(c => c !== colId)
            }
        })
    }

    onEditTableColumnDeleteCancel = colId => {
        this.setState({
            editTable: {
                ...this.state.editTable,
                columnData: {
                    ...this.state.editTable.columnData,
                    [colId]: {
                        ...this.state.editTable.columnData[colId],
                        removed: false,
                    }
                }
            }
        })
    }

    render() {
        return <Fragment>
            {Object.keys(this.state.editTable).length !== 0
                && <Modal show={Object.keys(this.state.editTable).length !== 0} no={this.onTableEditClick}>
                    <div className={styles.tableModal}>
                        <h1>Edit Table</h1>
                        <div className={styles.tableNameLabel}>
                            <label>Table Name</label>
                            <div className={styles.tableNameInput}>
                                <input
                                    type="textbox"
                                    value={this.state.editTable.name}
                                    maxLength="50"
                                    name="name"
                                    className={this.state.uniqueTableName !== null ? styles.inputError : null}
                                    onChange={this.onEditTableNameChange}
                                    autoFocus />
                                {this.state.editTable.tableNameEdited && this.state.uniqueTableName === null
                                    && <Fragment>
                                        <button onClick={this.onEditTableNameChangeSave} title="Save new table name"><i className="fa fa-floppy-o" /></button>
                                        <button onClick={this.onEditTableNameChangeCancel} title="The last one was better"><i className="fa fa-times" /></button>
                                    </Fragment>}
                            </div>
                            {this.state.uniqueTableName !== null
                                && <sub className={styles.inputError}>{this.state.uniqueTableName}</sub>}
                            <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
                        </div>
                        <h2>Columns</h2>
                        {Object.keys(this.state.editTable.columnData).length === 0
                            && <button onClick={() => { }} className={styles.noColumns}>No! I don't want to create a table with no columns</button>}
                        {this.state.editTable.columnOrder.map(cid => {
                            if (this.state.editTable.columnData[cid].removed) {
                                return <div key={'column' + cid} className={styles.tableColumnArea}>
                                    <label>Column '{this.state.editTable.columnData[cid].name}' and its data will be permanently removed if you click '<i className="fa fa-floppy-o" />'.</label>
                                    <button onClick={() => this.onEditTableColumnDeleteSave(cid)} title="Save column delete action"><i className="fa fa-floppy-o" /></button>
                                    <button onClick={() => this.onEditTableColumnDeleteCancel(cid)} title="Cancel this delete"><i className="fa fa-times" /></button>
                                </div>
                            } else {
                                return <div key={'column' + cid} className={styles.tableColumnArea}>
                                    <label>Column {this.state.editTable.columnOrder.indexOf(cid) + 1} name</label>
                                    <input
                                        type="textbox"
                                        value={this.state.editTable.columnData[cid].name}
                                        maxLength="50"
                                        name={cid}
                                        onChange={this.onEditTableColumnChange} />
                                    {'added' in this.state.editTable.columnData[cid] && this.state.editTable.columnData[cid].added
                                        ? <Fragment>
                                            <button onClick={() => this.onEditTableColumnAddSave(cid)} title="Add column"><i className="fa fa-floppy-o" /></button>
                                            <button onClick={() => this.onEditTableColumnAddCancel(cid)} title="Don't add column"><i className="fa fa-times" /></button>
                                        </Fragment>
                                        : this.state.editTable.columnData[cid].edited
                                            ? <Fragment>
                                                <button onClick={() => this.onEditTableColumnChangeSave(cid)} title="Rename column"><i className="fa fa-floppy-o" /></button>
                                                <button onClick={() => this.onEditTableColumnChangeCancel(cid)} title="Undo rename"><i className="fa fa-times" /></button>
                                            </Fragment>
                                            : <Fragment>
                                                <button onClick={() => this.onEditTableColumnAdd(this.state.editTable.columnOrder.indexOf(cid) + 1)} title="Add a new column">+</button>
                                                <button onClick={() => this.onEditTableColumnDelete(cid)} title="Delete this column">-</button>
                                            </Fragment>}
                                </div>
                            }
                        })}
                    </div>
                </Modal>
            }
            <div className={styles.container}>
                <LeftSideNav applab />
                <div className={styles.table}>
                    <div className={styles.tableHeader}>
                        <div>
                            <h1 className={styles.tableName}>{this.state.table.name}</h1>
                            {'id' in this.state.table && <button className={styles.tableEdit} onClick={this.onTableEditClick}>Edit Table</button>}
                        </div>
                        <Link to="/"><span>&lt; Back</span></Link>
                    </div>
                    <div id="hot-app">
                        <HotTable
                            data={this.state.data}
                            dataSchema={this.state.dataSchema}
                            columns={this.state.columns}
                            licenseKey="non-commercial-and-evaluation"
                            colHeaders={this.state.colHeaders}
                            rowHeaders={true}
                            minSpareRows={5}
                            beforeChange={this.beforeChange}
                            afterChange={this.afterChange}
                            // beforePaste={this.beforePaste}
                            // afterPaste={this.afterPaste}
                            contextMenu={{
                                items: {
                                    'row_above': {},
                                    'row_below': {},
                                    // '---------': {},
                                    // 'remove_row': {
                                    //     "callback": (key, selection, clickEvent) => {
                                    //         let startRow = selection[0].start['row']
                                    //         let endRow = selection[0].end['row']
                                    //         for (; startRow <= endRow; startRow++) {
                                    //             // console.log('deleting row', startRow)
                                    //             this.props.firebase.deleteRow(this.userId, this.state.tableId, startRow)
                                    //         }
                                    //     }
                                    // },
                                }
                            }}
                            width={0.7 * window.innerWidth}
                            height={0.7 * window.innerHeight}>
                        </HotTable>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

const TableScreen = (props) => <FirebaseContext.Consumer>{firebase => <Table firebase={firebase} {...props} />}</FirebaseContext.Consumer>

export default TableScreen
